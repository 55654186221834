.tabs-container {
  border-radius: 20px !important; }
  .tabs-container .ant-tabs-content {
    margin-top: -16px !important; }
    .tabs-container .ant-tabs-content .ant-tabs-tabpane {
      background: #ffffff !important;
      padding: 30px 16px !important;
      border-bottom-left-radius: 20px !important;
      border-bottom-right-radius: 20px !important; }
      .tabs-container .ant-tabs-content .ant-tabs-tabpane-inactive {
        display: none !important; }
  .tabs-container .ant-tabs-bar {
    background: #e5e5e5 !important;
    border-color: #ffffff !important; }
    .tabs-container .ant-tabs-bar .ant-tabs-nav-container {
      height: auto !important; }
      .tabs-container .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab {
        background: transparent !important;
        border-color: transparent !important;
        border-radius: 0 !important;
        height: auto !important;
        font-size: 16px !important;
        padding: 20px 40px !important; }
        .tabs-container .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab:hover:not([aria-disabled='true']) {
          color: rgba(0, 0, 0, 0.85) !important; }
        .tabs-container .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab-active {
          color: rgba(0, 0, 0, 0.85) !important;
          background: #ffffff !important;
          border-color: #ffffff !important;
          transition: none !important; }
          .tabs-container .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-tab-active:hover:hover {
            color: rgba(0, 0, 0, 0.85) !important; }
